<template>
    <div style="max-width: 300px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <el-form-item prop="name" label="角色名称">
                <el-input placeholder="请输入角色名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item prop="desc" label="角色说明">
                <el-input type="textarea" :rows="2" placeholder="请输入角色说明" v-model="ruleForm.desc"></el-input>
            </el-form-item>
            <div>
                <div style="font-size:14px;font-weight:600;line-height:30px;border-bottom:1px solid #000;">角色权限</div>
                <div class="list" v-for="(item,idx) in selectList" :key="idx">
                    <div>{{item.name}}</div>
                    <div style="padding-left:10px;">
                        <el-checkbox-group v-model="checkList">
                            <el-checkbox v-for="(item1,idx1) in item.privs" :key="idx1" :label="item1.id">
                                {{item1.name}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <el-button type="primary" @click="submitForm()" style="width: 100%;">提交</el-button>
        </el-form>
    </div>
</template>
<script>
    import { getCanAssignPrivs,addRole,setRole } from '@/service/purview';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入权限分类名称', trigger: 'blur' }
                    ],
                    desc: [
                        { required: true, message: '请输入权限分类说明', trigger: 'blur' }
                    ]
                },
                ruleForm:{
                    name:"",
                    desc:"",
                    privs: "",
                },
                checkList:[],
                selectList:[],
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                    this.checkList = this.form.privs;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            this.checkList = this.form.privs;
            // console.log(this.ruleForm);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getCanAssignPrivs().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.ruleForm.privs = this.checkList;
                if (this.popTitle == "新建角色") {
                    addRole(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("添加成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == "修改角色信息") {
                    setRole(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message); 
                    });
                }
            },
        }
    }
</script>
<style scoped>
    .list>div {
        padding: 5px 0;
    }
    
</style>
<template>
    <div style="height:100%">
        <div class="page-header">角色管理</div>
        <div style="margin:20px;text-align:right;">
            <el-button @click="add()" size="small" type="primary">新建角色</el-button>
        </div>
        <div class="block" style="height: calc(100% - 159px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="name" label="角色" width="120">
                    </el-table-column>
                    <el-table-column prop="privs" label="权限">
                        <template slot-scope="scope">
                            <span v-for="(item,idx) in scope.row.privs" :key="idx" style="margin-right:10px;">
                                <span style="font-size:13px;">{{item.name}}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row)">
                                <span class="icon iconfont icon-bianji1"></span>&nbsp;修改
                            </el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">
                                <span class="icon iconfont icon-shanchu"></span>&nbsp;删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditRole :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></EditRole>
        </el-dialog>
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    import EditRole from './EditRole.vue';
    export default {
        components: {
            EditRole
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                queryOpt:{
                    pageNumber:1,
                    pageCount:15,
                },
                total:0,
                list:[],
                ruleForm:{
                    name:"",
                    desc:"",
                    privs:[],
                }
            }
        },
        mounted() {

        },
        created() {
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    if (rst && rst.length>0) {
                        this.list = rst;
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add() {
                this.popTitle = "新建角色";
                this.popVisible = true;
                this.ruleForm = {
                    name:"",desc:"",privs:[],
                };
            },
            edit(item) {
                this.popTitle = "修改角色信息";
                this.popVisible = true;
                this.ruleForm = {
                    role_id:item.id,
                    privs:[],
                    name:item.name,desc:item.desc,
                };
                if (item.privs && item.privs.length>0) {
                    this.ruleForm.privs = item.privs.reduce((pre,cur)=>{
                        pre.push(cur.id);
                        return pre;
                    },[]);
                }
                console.log(this.ruleForm);
            },
            del(item) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delRol({role_id:item.id}).then(rst => {
                        this.refreshItems();
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .icon {
        font-size: 13px;
    }
</style>